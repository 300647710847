import {
  GET_ALL_INVITE_CODE,
  ADD_INVITE_CODE,
  UDPATE_INVITE_CODE,
} from "../../actions/wescoverActions/inviteCodeAction";

function SellerReducer(
  state = {
    get_all_invite_code: [],
    add_invite_code: [],
    update_invite_code: [],
  },
  action
) {
  switch (action.type) {
    case GET_ALL_INVITE_CODE:
      return Object.assign({}, state, {
        get_all_invite_code: action.get_all_invite_code,
      });
    case ADD_INVITE_CODE:
      return Object.assign({}, state, {
        add_invite_code: action.add_invite_code,
      });
    case UDPATE_INVITE_CODE:
      return Object.assign({}, state, {
        update_invite_code: action.update_invite_code,
      });
    default:
      return state;
  }
}

export default SellerReducer;
