import Axios from 'axios';
const config = require("../../../utils/generalconfig.json");

export const GET_ALL_INVITE_CODE = 'get_all_invite_code';
export const ADD_INVITE_CODE = 'add_invite_code';
export const UDPATE_INVITE_CODE = 'update_invite_code';


let baseurl = config.api_prod_url;
if (window.location.hostname === 'admin.bookanartist.co') {
    baseurl = config.api_prod_url
} else if (window.location.hostname === 'admin.hireanartist.com.au') {
    baseurl = config.api_stage_url
} else if (window.location.hostname === 'admin.hireastreetartist.co.uk') {
    baseurl = config.api_stage_url_v2
} else if (window.location.hostname === 'localhost') {
    baseurl = config.api_local_url
}
// /////////
function handleUnAuthorised() {
    let source = window.location.pathname
    if (source !== '/' && source !== '/logout') {
        source = '?source=' + source
    } else {
        source = ''
    }
    window.location.href = '/' + source
}

export function getAllInviteCode(post) {
    return dispatch => {
        return Axios.post('api/get_all_invite_code', post, { baseURL: baseurl })
            .then(response => {
                const data = response.data || [];
                dispatch(requestGetAllInviteCode(data));
            })
            .catch(error => {
                if (error.response) {
                    if (error.response.status === 403) {
                        handleUnAuthorised();
                        // dispatch(push('/')); // Uncomment if redirection is needed
                    } else {
                        console.error(error);
                    }
                }
            });
    };
}

function requestGetAllInviteCode(response) {
    return {
        type: GET_ALL_INVITE_CODE,
        get_all_invite_code: response
    }
}

export function EmptyGetAllInviteCode(response) {
    return {
       type: GET_ALL_INVITE_CODE,
       get_all_invite_code: []
     }
   }
export function addInviteCode(post) {
    return dispatch => {
        return Axios.post('api/add_invite_code', post, { baseURL: baseurl })
            .then(response => {
                const data = response.data || [];
                dispatch(requestAddInviteCode(data));
            })
            .catch(error => {
                if (error.response) {
                    if (error.response.status === 403) {
                        handleUnAuthorised();
                        // dispatch(push('/')); // Uncomment if redirection is needed
                    } else {
                        console.error(error);
                    }
                }
            });
    };
}

function requestAddInviteCode(response) {
    return {
        type: ADD_INVITE_CODE,
        add_invite_code: response
    }
}

export function EmptyaddInviteCode(response) {
 return {
    type: ADD_INVITE_CODE,
    add_invite_code: []
  }
}
export function updateInviteCode(post) {
    return dispatch => {
        return Axios.post('api/update_invite_code', post, { baseURL: baseurl })
            .then(response => {
                const data = response.data || [];
                dispatch(requestUpdateInviteCode(data));
            })
            .catch(error => {
                if (error.response) {
                    if (error.response.status === 403) {
                        handleUnAuthorised();
                        // dispatch(push('/')); // Uncomment if redirection is needed
                    } else {
                        console.error(error);
                    }
                }
            });
    };
}

function requestUpdateInviteCode(response) {
    return {
        type: UDPATE_INVITE_CODE,
        update_invite_code: response
    }
}

export function EmptyUpdateInviteCode(response) {
 return {
    type: UDPATE_INVITE_CODE,
    update_invite_code: []
  }
}